
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ToteutussuunnitelmaDataStore } from '@/stores/ToteutussuunnitelmaDataStore';
import { Koulutustyyppi } from '@shared/tyypit';
import EpFormContent from '@shared/components/forms/EpFormContent.vue';
import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';
import EpField from '@shared/components/forms/EpField.vue';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import * as _ from 'lodash';
import { Kielet } from '@shared/stores/kieli';

@Component({
  components: {
    EpContentViewer,
    EpFormContent,
    EpField,
    EpSpinner,
  },
})
export default class EpOpetussuunnitelmaTiedot extends Vue {
  @Prop({ required: true })
  private store!: ToteutussuunnitelmaDataStore | null;

  @Watch('kieli')
  async kieliChanged() {
    await this.store!.getDokumenttiTila();
  }

  get dokumenttiUrl() {
    return this.store!.dokumenttiUrl;
  }

  get kieli() {
    return Kielet.getSisaltoKieli.value;
  }

  get opetussuunnitelma() {
    return this.store!.opetussuunnitelma;
  }

  get koulutustoimija() {
    return this.opetussuunnitelma?.koulutustoimija;
  }

  get koulutustyyppiName() {
    if (this.opetussuunnitelma?.jotpatyyppi !== 'MUU') {
      return this.$t(this.store!.koulutustyyppi as Koulutustyyppi);
    }
  }

  get oppilaitosTyyppiNimi() {
    if (this.opetussuunnitelma?.oppilaitosTyyppiKoodi) {
      return _.mapValues(_.keyBy(this.opetussuunnitelma?.oppilaitosTyyppiKoodi.metadata, v => _.toLower(v.kieli)), v => v.nimi);
    }
  }

  get kuvat() {
    return this.store!.kuvat;
  }
}
